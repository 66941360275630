import React from "react"
import { Global, css } from '@emotion/core';
import Header from './header';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {rhythm} from "../utils/typography";
import SectionImage from "./section_image";
// import { faTwitter } from '@fortawesome/free-solid-svg-icons'
library.add(fab, faTwitter);

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    // const rootPath = `${__PATH_PREFIX__}/`;



    return (
      <>
        <Global
          styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          /* More info: https://bit.ly/2PsCnzk */
          * + * {
            #margin-top: 1rem;
          }

          html,
          body {
            margin: 0;
            color: #555;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 14px;
            line-height: 1.4;

            @media (min-width: calc(550px + 10vw)) {
              font-size: 18px;
            }

            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
          }

          footer {
            margin-top: 50px;
            padding-right: 12px;
            text-align: right;
            font-size: .75em;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #111;
            font-size: 1rem;
            line-height: 1.1;
             font-family: Helvetica;

            + * {
              margin-top: 0.5rem;
            }
          }

          h1 {
            font-size: 1.7rem;
          }
          h2 {
            font-size: 1.2rem;
          }
          h3 {
            font-size: 1.1rem;
          }

          h3 a {
            font-style: none;
            font-family: "Trebuchet MS", Verdana;
            font-weight: normal;
            font-size: .9em;
            color: #d3785e;
          }

          wrapper {
            margin: 0 auto;
            width: 768px;
          }

          strong {
            color: #222;
          }

          li {
            margin-top: 0.25rem;
          }

          header nav {
            background-color: #111;
            padding: 6px;
            color: #f3f3f3;
          }
          header nav a {
            color: #f3f3f3;
          }

          footer {
            background-color: #283f43;
            color: #f3f3f3;
            min-height: 50px;
            text-align: center;
          }

          article footer {
            #background-color: #007acc;
          }

          footer strong {
            color: #fffab0;
          }

          footer a {
            color: #f3f3f3;
          }

          .img-break {
            width: 100%;
            margin-bottom: 20px;
          }

          .img-break .image {
            background-color: #333;
          }

          .img-break.light .image {
            background-color: #f3f3f3;
          }

          .img-break .caption {
            text-align: center;
            font-size: .8em;
            font-style: italic;
            margin-top: 2px;

          }

          article header h1 {
            width: 768px;
            margin: 0 auto;
          }
          article header h2,
           article header h3 {
            width: 768px;
            margin: 0 auto;
            padding-left: 6px;
            color: #777;
            font-size: .95em;
            font-weight: normal;
          }
          article header p {
            width: 768px;
            margin: 0 auto;
            margin-top: 2px;
            padding-left: 6px;
            font-size: .75rem;
          }

          article section h3,
          article section h1,
          article section h2 {
            width: 768px;
            margin: 0 auto;
            margin-top: 40px;
            margin-bottom: 12px;
          }
          article section h3 {
            color: #333;
            margin-top: 30px;
            font-size: 1.2em;
          }
          article section p {
            width: 768px;
            margin: 0 auto;
            margin-bottom: 18px;
          }
          article section ul, article section ol {
            width: 768px;
            margin: 0 auto;
            padding-left: 50px;
          }
          article section .gatsby-highlight {
            width: 768px;
            margin: 0 auto;
          }
          article section blockquote {
            background-color: #f3f3f3;
            padding: 24px;
            margin-right: 0;
          }
          article section blockquote p {
            border-left: 4px solid #ccc;
            padding-left: 12px;
            font-size: .85em;
          }
          article section hr {
            margin-bottom: 1.75rem;
          }
          article section nav {
            width: 768px;
            margin: 0 auto;
          }
        `}
        />
        <Header
          css={css`
          background-color: #111111;
          font-size: 2em;
        `}
        />
        <main
          style={{
            marginTop: 0,
            padding: 0,
          }}>
          {children}
        </main>
        <footer>
          <SectionImage style={{height: `100%`}} alt="Mountains" filename="mountain-sky-night.png">
            <div style={{marginTop: `25px`}}>Copyright &copy; {new Date().getFullYear()}, Mike Milano</div>
          </SectionImage>
        </footer>
      </>
    )
  }
}

export default Layout
