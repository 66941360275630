import React from 'react';
import styled from '@emotion/styled';
// import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MidContainer = styled('div')`
  width: 768px;
  margin: 0 auto;

`;

const TwitterLink = styled('a')`
  color: #a1a1a1;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  box-shadow: none;

  &:last-of-type {
    margin-right: 0;
    float: right;
  }
`;

const NavLink = styled(Link)`
  color: #a1a1a1;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  box-shadow: none;

  &.current-page {
    border-bottom: 2px solid #222;
  }

  &:last-of-type {
    margin-right: 0;
    float: right;
  }
`;

const Header = () => (
  <header>
    <nav>
      <MidContainer>
        <NavLink to="/" activeClassName="current-page">
          Home
        </NavLink>
        {/*<NavLink to="/about/" activeClassName="current-page">*/}
        {/*  About*/}
        {/*</NavLink>*/}
          <TwitterLink target="_blank" className="twitter-link" href="https://twitter.com/mikemilano">
          <FontAwesomeIcon icon={["fab", "twitter"]} style={{color:"#1da1f2"}} /> @mikemilano
          </TwitterLink>
      </MidContainer>
    </nav>
  </header>
);

export default Header;
