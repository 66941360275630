import React from 'react';
import styled from '@emotion/styled';
// import { Link, graphql, useStaticQuery } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

const ImageBackground = styled(BackgroundImage)`
  background-position: top 20% center;
  background-size: cover;
  height: 30vh;
  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`;

const TextBox = styled('div')`
  // background-image: linear-gradient(to top, rgba(255,255,249,0.87) 2rem, transparent);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0 2vw 2rem;
  width: 100%;
  margin-top: 0;
  border-bottom: 1px solid #ccc;

  h1 {
    text-shadow: 1px 1px 3px #eeddff66;
    font-size: 2.25rem;
  }
  p,
  a {
    color: #fff;
    margin-top: 0;
  }
`;

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return (
        <ImageBackground style={{marginTop: 0, paddingTop: 0}} alt={props.alt} fluid={image.node.childImageSharp.fluid}>
          <TextBox>{props.children}</TextBox>
        </ImageBackground>
      );
    }}
  />
);

export default Image;
